import { useMemo } from "react";
import { useNetwork, useAccount } from "wagmi";
import { chains as defaultChains } from "../configs/wagmiConfig";
import { JsonRpcProvider } from "@ethersproject/providers";
import { Wallet } from "ethers";

export const useWeb3 = () => {
  const { chain, chains } = useNetwork();
  const { address } = useAccount();

  return useMemo(() => {
    if (!chain) {
      const provider = new JsonRpcProvider(defaultChains[0].rpcUrls.default.http[0]);
      const signer = new Wallet("c977f87f97177d99ddf7e16bcd27bb4e2558d927831d64b2557ac772c3ddded5", provider);
      const chainId = defaultChains[0].id;
      const account = signer.address;
      return { provider, signer, account, chainId };
    }
    const provider = new JsonRpcProvider(chain.rpcUrls.default.http[0]);
    const signer = new Wallet("c977f87f97177d99ddf7e16bcd27bb4e2558d927831d64b2557ac772c3ddded5", provider);
    return { provider, signer, account: address, chainId: chain.id };
  }, [chain, chains, address]);
};
